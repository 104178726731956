<template>
  <div class="content">
    <ApplicationNannyInputEducationDegree
      v-model:education-degree="applicationNanny.education.degree"
    />

    <ButtonPrimaryNext
      :to="{ name: nextViewName }"
      :isDisabled="!applicationNanny.education.degree"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputEducationDegree from "@/components/Application/Nanny/ApplicationNannyInputEducationDegree.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    ButtonPrimaryNext,
    ApplicationNannyInputEducationDegree,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const nextViewName = computed(() => {
      return applicationNanny.education.degree === "student"
        ? "ApplicationNannyCreateEducationTypeStudent"
        : "ApplicationNannyCreateEducationType";
    });

    return {
      applicationNanny,
      nextViewName,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
