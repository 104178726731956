<template>
  <div class="container">
    <BaseH1
      :text="'Zaznacz najwyzszy ukonczony poziom edukacji'"
      class="container__h1"
    />

    <InputRadioGroupVertical
      v-model="application.educationDegree"
      :name="'education-degree'"
      :options="educationDegreeOptions"
    />
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupVertical from "@/components/UI/Base/InputRadioGroupVertical.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupVertical,
  },

  props: {
    educationDegree: String,
  },

  emits: ["update:educationDegree"],

  setup(props, { emit }) {
    const application = reactive({
      educationDegree: props.educationDegree,
    });

    watch(
      () => application.educationDegree,
      (newEducationDegree) => emit("update:educationDegree", newEducationDegree)
    );
    watch(
      () => props.educationDegree,
      (newEducationDegree) => (application.educationDegree = newEducationDegree)
    );

    const educationDegreeOptions = [
      {
        label: "Wyzsze",
        value: "university",
      },
      {
        label: "Student",
        value: "student",
      },
      {
        label: "Zawodowe",
        value: "college",
      },
      {
        label: "Podstawowe",
        value: "school",
      },
    ];

    return {
      application,
      educationDegreeOptions,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__h1 {
  width: 560px;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .container__h1 {
    width: 100%;
  }
}
</style>
